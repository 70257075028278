import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
// -----------------------------------------
const host = "https://mink.imgix.net/"

const Picture = props => {
  const {
    className,
    width,
    height,
    path,
    force,
    resize,
    hires,
    mimeType,
    url,
  } = props

  const img = useRef()
  const [src] = useState(`${host}${path}`)
  const [dpr, setDpr] = useState(1.5)
  const [loaded, setLoaded] = useState(false)
  const [show, showImage] = useState(false)
  const aspectRatio = width / height
  const svgWidth = 12000 * aspectRatio
  const svgHeight = 12000
  const gif = mimeType === "image/gif" ? url : false

  // NOTE: Get pixel density to add to imgix params...
  useEffect(() => {
    if (process.browser && window.devicePixelRatio) {
      setDpr(window.devicePixelRatio)
    }
  }, [])

  // NOTE: If image has already loaded, then show it
  useEffect(() => {
    if (img.current.complete) {
      setLoaded(true)
    }
    if (force) {
      setLoaded(true)
      // showImage(true);
    }
  }, [src])

  // NOTE: When image is loaded, show image after 0.3s
  useEffect(() => {
    let timer
    clearTimeout(timer)
    if (!show) {
      timer = setTimeout(() => {
        showImage(true)
      }, 500)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [loaded])

  return (
    <picture className={`ratio ${className} ${show ? "show" : "hide"}`}>
      <svg
        width={svgWidth}
        height={svgHeight}
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      >
        <rect
          width={svgWidth}
          height={svgHeight}
          fill={!src ? "#FF0000" : "rgba(0, 0, 0, 0.1)"}
        />
      </svg>
      <img
        src={
          gif ||
          `${src}?${!hires ? "auto=format" : ""}fit=crop&fm=${resize.format ||
            "jpg"}&w=${resize.width || "auto"}&h=${resize.height ||
            "auto"}&q=${resize.quality || "70"}&dpr=${dpr}`
        }
        onLoad={e => setLoaded(true)}
        ref={img}
        alt=""
      />
    </picture>
  )
}

Picture.defaultProps = {
  className: "",
  style: {},
  src: "",
  alt: "",
  width: 1000,
  height: 1000,
}

export default Picture

export const assetFragment = graphql`
  fragment Asset on Craft_minkAssets_Asset {
    id
    url
    width
    height
    path
    mimeType
  }
`
