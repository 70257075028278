import React, { useEffect, useState, useCallback, useRef } from "react";
import Player from '@vimeo/player'
// import cleanUrl from "../helpers/cleanUrl";
// import fetch from "fetch-hoc";

function useInitialise(options) {

	const ref = useRef()
	const [player, setPlayer] = useState(null);
	const { url, reveal, autoplay, withControls } = options

	// Don't think I need this isInitialized flag...?
	const init = useCallback(() => {
    let isInitialized = true;
    if (isInitialized && !player && ((url && !reveal) || (reveal && reveal.isVisible))) {
      const initPlayer = new Player(ref.current, {
        url: url,
        portrait: false,
        title: false,
        autoplay: autoplay ? true : false,
        quality: !withControls ? '240p' : '',
        background: !withControls ? 1 : 0,
        dnt: true,
				maxWidth: 1340,
      })
      setPlayer(initPlayer);
    }
    return () => {
      isInitialized = false;
      if (player) {
        player.destroy()
        setPlayer(null)
      }
    }
  }, [reveal, player, setPlayer, url, autoplay, withControls, ref])
	useEffect(init, [reveal])

	return [ref, player, setPlayer]
}

function useVideoSize(player, props) {
	const { videoWidth, videoHeight } = props
	const [size, setSize] = useState({ 
		width: videoWidth || 1600, 
		height: videoHeight || 900 
	})

	const getSizes = useCallback(() => {
		if (player && !videoWidth && !videoHeight) {
      Promise.all([player.getVideoWidth(), player.getVideoHeight()])
        .then((dimensions) => {
					if (dimensions) {
						setSize({ width: dimensions[0], height: dimensions[1] })
					}
        });
    }
	}, [videoWidth, videoHeight, player])
	useEffect(getSizes, [videoWidth, videoHeight, player])

	return size
}

function useCuePoints(player, props) {
	const { withControls, startTime, endTime } = props
	const start = parseInt(startTime) || 0
	const end = parseInt(endTime) || 5

	const setCuePoints = useCallback(() => {
		if (player && !withControls) {
			player.setCurrentTime(start)
			
			const handleLoop = () => {
				player.setCurrentTime(start)
			}
			
      player.addCuePoint(end, {
				customKey: 'loopThumbnail'
			}).then(() => {
				player.on('cuepoint', handleLoop)
			})
    }
  }, [player, start, end, withControls])

	useEffect(setCuePoints, [player])

	return;
}

const Vimeo = (props) => {

	// Content
  const { 
		className, 
		withControls, 
		withOverlay = false,
		autoplay, 
		reveal, 
		startTime = 0, 
		endTime, 
		videoWidth, 
		videoHeight,
		onMouseEnterTooltipContainer,
		onMouseLeaveTooltipContainer
	} = props
	const url = props.vimeoUrl || props.url

	// UX
	const [videoRef, player] = useInitialise({ url, autoplay, reveal, withControls })
	const { width, height } = useVideoSize(player, { videoWidth, videoHeight })
	useCuePoints(player, { withControls, startTime, endTime })

	useEffect(() => {
		if(player && startTime) {
			player.on('ready', () => {
				player.setCurrentTime(parseInt(startTime))
			})
		}
	}, [player])

  // UI
	const handlePlayPause = () => {
		if (player) {
			player.getPaused().then((isPaused) => {
				if (isPaused) {
					player.play()
				} else {
					player.pause()
				}
			}).catch((error) => {
				console.error(error)
			})
		}
	}

	const isLandscapeOrientation = width > height
	const styles = withOverlay ? {
		paddingTop: isLandscapeOrientation ? `${(height / width) * 100}%` : 0,
		height: isLandscapeOrientation ? 0 : '100%'
	} : {}

  return (
		<div
			className={`video prel inlb ${className || ''}`}
			style={styles}
		>
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
				<rect width={width} height={height} fill="rgba(0, 0, 0, 0.1)" />
      </svg>
			<div className="pabs fs fs-iframe" ref={videoRef} />
			<div
				className="slide-video-overlay pabs fs"
				onClick={handlePlayPause}
				onMouseEnter={onMouseEnterTooltipContainer}
				onMouseMove={onMouseEnterTooltipContainer}
				onMouseLeave={onMouseLeaveTooltipContainer}
			/>
      {/* {!withControls && (
        <div className="pabs fs" onClick={handlePlayPause} />
      )} */}
    </div>
  )
}

Vimeo.defaultProps = {
  url: 'https://vimeo.com/321249877',
	onTooltipMouseEnter: null,
	onTooltipMouseLeave: null
};

export default React.memo(Vimeo, (prev, next) => {
  return prev.reveal ? prev.reveal.isVisible === next.reveal.isVisible : prev.url === next.url
});
